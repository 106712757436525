import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { useLangContext } from '../context/lang.context'
import {
  sContainer,
  sTitle,
  sHtml,
  sContainerPadding,
  sCenteredSpaced,
} from '../style'
import Seo from '../components/Seo'
import Button from '../components/Button'

export default function SpecificationTemplate({ data }) {
  const { lang } = useLangContext();
  const {
    title,
    slug,
    desc,
    producer
  } = data.specification

  const content = {
    producer: {
      pl: `Producent`,
      en: `Producer`,
      zh: `制片人`
    }
  }

  const buttonBack = {
    text: {
      pl: 'Wróć do produktu',
      en: 'Back to product',
      en: 'Powrót do produktu',
    },
    link: {
      pl: `/sklep/` + slug,
      en: `/shop/` + slug,
      en: `/shop/` + slug,
    }
  }


  return (
    <Layout white>
      <Seo
        title={title}
        url={'specification-' + slug} // https://omidatrade.pl/blog/slug
        noIndex
      />
      <section css={[sContainer, sContainerPadding, sWrapper]}>
        <h1 css={[sTitle, { fontSize: '2rem' }]}>{title}</h1>
        {desc && (
          <div className={`productDescSection`} css={productDescSection}>
            <article
              css={[sHtml]}
              dangerouslySetInnerHTML={{
                __html: desc.childMarkdownRemark.html.replaceAll(
                  'h1>',
                  'h2>'
                ),
            }} />
          </div>
        )}
        {producer && (
          <div css={productDescSection}>
            <h3><strong>{content.producer[lang]}</strong></h3>
            <article
              css={[sHtml]}
              dangerouslySetInnerHTML={{
                __html: producer.producerDesc.childMarkdownRemark.html.replace(/\n/g, '<br>')
            }} />
          </div>
        )}
        <div css={sCenteredSpaced}>
          <Button glow link={buttonBack.link[lang]}>
            {buttonBack.text[lang]}
          </Button>
        </div>
      </section>
    </Layout>
  )
}

const sWrapper = {
  '.productDescSection:first-of-type': {
    paddingTop: 0,
  }
}

const productDescSection = {
  padding: '3rem 0 0',
  'article': {
    padding: '0',
  },
  'article h2, article h3': {
    padding: '1rem 0 0.5rem',
  },
  'article ul li, article ol li': {
    color: '#2b2a29',
  },
  'article p': {
    marginBottom: '1rem',
  },
}


export const query = graphql`
  query ($id: String!) {
    specification: contentfulSpecyfikacjaProduktu(id: { eq: $id }) {
      id
      title
      slug
      desc {
        childMarkdownRemark {
          html
        }
      }
      producer {
        producerName
        producerDesc {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`